import {Injectable} from "@angular/core";


@Injectable({providedIn: "root"})
export class ChooseEnterpriseService {

    visibility:any = {state: false};

    userEnterprise: any = {id: ''};

}
