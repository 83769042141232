<p-dialog
        [dismissableMask]="activeUserEnterprise"
        [closable]="activeUserEnterprise"
        [modal]="true"
        [(visible)]="visibility.state"
        [style]="{ width: '95vw', height: '85vh' }">

    <ng-template pTemplate="header">
        <span class="text-xl font-bold"></span>
    </ng-template>

    <div class="flex flex-nowrap">
        <div class="flex-grow-1"></div>
        <div class="flex-grow-0" class="enterprise-list-content">
            <h3>Unternehmen auswählen:</h3>
            <input pInputText class="enterprise-list-searchbar" [(ngModel)]="filterTerm" (input)="filterList()">
            <div>
                <div *ngFor="let enterprise of filteredList">
                    <div class="enterprise-list-element"
                         [ngClass]="checkActiveUserEnterprise(enterprise.id)"
                         (click)="changeEnterprise(enterprise.id)">
                        <div class="title">{{enterprise.name}}</div>
                        <div class="address">{{enterprise.fullAddress}}</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="flex-grow-1"></div>
    </div>

    <ng-template pTemplate="footer">
    </ng-template>


</p-dialog>
